export default {
    //top-nav
    Home: "首頁",
    Search: "搜索",
    Lead: "潛在客戶",
    Prospects: "潛在客戶",
    filterYourSearchToFindHighlySpecific: "過濾您的搜索以找到高度特定的",
    groupsOfTargetedLeads: "目標潛在客戶組",
    filterYourSearchToFindPreciselyTargeted: "過濾您的搜索以找到精確定位的",
    listsOfCompanies: "公司列表",
    transformAnonymousWebsiteTrafficInto: "將匿名網站流量轉換為",
    b2bLeadsWithRealtimeIdentification: "實時識別的B2B潛在客戶",
    discoverAndConnectWithPotentialBuyers: "發現並與潛在客戶聯繫",
    activelySeekingYourSolutionToday: "今天積極尋求您的解決方案",
    Intent_Signals: "意圖信號",
    Website_Intent: "網站意圖",
    Market_Intent: "市場意圖",
    Sequence: "工作流程",
    Tools: "工具",
    Services: "企業服務",
    technologyLookup: "技術查詢",
    domainLookup: "域名查詢",
    emailVerification: "電子郵件驗證",
    upgrade: "升級",
    helpCenter: "幫助中心",
    contactUs: "聯繫我們",
    feedback: "反饋",
    hello: "你好",
    clickToChange: "點擊更改",
    yourProfile: "您的個人資料",
    currentUsage: "當前使用情況",
    upgradePlan: "升級計劃",
    integrations: "集成",
    featureRequests: "功能請求",
    change: "更改",
    en: "英文",
    cn: "中文（简）",
    tw: "中文（繁）",
    logout: "登出",
    switchLanguage: "成功切換語言",
};