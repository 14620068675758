<template>
  <div class="scroll-loader" ref="wrap">
    <slot>
      <div class="content"></div>
    </slot>

    <div ref="flag" class="flag" v-show="!disableObserve || loading">
      <i class="el-icon-loading"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "scroll-loader",
  props: ["disableObserve", "loading"],
  data() {
    return {
      observer: undefined,
      comming: false,
      timer: undefined,
    };
  },
  watch: {
    comming(value) {
      if (value) {
        if (this.timer || this.loading) {
          return;
        }
        const _this = this;
        this.timer = setTimeout(async () => {
          await this.$emit("handleQuery");
          clearTimeout(_this.timer);
          _this.timer = undefined;
        }, 200);
      }
    },
    disableObserve(value) {
      if (value) {
        this.observer.unobserve(this.$refs.flag);
      }
    },
  },
  methods: {
    callback(entries) {
      const _this = this;
      entries.forEach((entry) => {
        _this.comming = entry.isIntersecting;
      });
    },
  },
  mounted() {
    const options = {
      root: this.$refs.wrap,
      rootMargin: "0px",
      threshold: 0.75,
    };

    this.observer = new IntersectionObserver(this.callback, options);
    this.observer.observe(this.$refs.flag);
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.observer.unobserve(this.$refs.flag);
  },
};
</script>

<style lang="scss" scoped>
.scroll-loader {
  overflow: auto;

  .flag {
    width: 100%;
    min-height: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0;

    .svg-icon {
      animation: spin 1s infinite linear;
      margin: 6px 0px;
    }
  }
}
</style>
