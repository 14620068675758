import * as people from "@/api/people";

const state = {
  selected: {
    search_term: '',
    people_groups: [],
    people_name: '',
    people_titles: [],
    departments: [],
    department_functions: [],
    management_levels: [],
    employees: [],
    verticals: [],
    vertical_categories: [],
    people_locations: [],
    company_locations: [],
    companies: [],
    technologies: [],
    categories: [],
    page: 1,
    per_page: parseInt(process.env.VUE_APP_PER_PAGE),
  },
  checkedList: [],
  isLoading: false, 
  isShow: false,
  lists: [], 
  total: {},
  peopleExportFields: [],
  multipleSelectedListIds: [],
  savedList: {}
}
const mutations = {
  setCollapse(state) {
    state.isShow = !state.isShow
  },
  setLoading(state, val) {
    state.isLoading = val
  },
  setCheckedList(state, payload) {
    state.checkedList = payload;
  },
  setSavedList(state, payload) {
    state.savedList = payload;
  },
  setMultiEmployeeAddSavedList(state, addedGroupInfo) {
    const ids = state.checkedList.map((item) => item.people_search_id);
    state.lists = state.lists.map((item) => {
      if (ids.includes(item.people_search_id)) {
        if (item.saved_lists) {
          const groupIds = item.saved_lists.map((group) => group.group_id);
          return groupIds.includes(addedGroupInfo.group_id) ? item : { 
            ...item,
            saved_lists: [...item.saved_lists, addedGroupInfo],
          }
        } else {
          return {
            ...item,
            saved_lists: [addedGroupInfo]
          }
        }
      } else {
        return item;
      }
    })
  },
  setIntentRowAddSavedList(state, payload) {
    const { people_search_id, group } = payload;
    state.lists = state.lists.map((item) => {
      if (item.people_search_id === people_search_id) {
        if (item.saved_lists) {
          const groupIds = item.saved_lists.map((groupItem) => groupItem.group_id);
          return groupIds.includes(group.group_id) ? item : { 
            ...item,
            saved_lists: [...item.saved_lists, group],
          }
        } else {
          return {
            ...item,
            saved_lists: [group],
          }
        }
      } else {
        return item;
      };
    })
  },
  setMultiEmployeeDeleteSavedList(state, groupInfo) {
    const ids = state.checkedList.map((item) => item.people_search_id);
    state.lists = state.lists.map((item) => {
      return ids.includes(item.people_search_id) ? {
        ...item,
        saved_lists: item.saved_lists.filter((groupItem) => groupItem.group_id !== groupInfo.group_id),
      } : item;
    })
  },
  setIntentRowDeleteSavedList(state, payload) {
    const { people_search_id, group } = payload;
    state.lists = state.lists.map((item) => {
      if (item.people_search_id === people_search_id) {
        return {
          ...item,
          saved_lists: item.saved_lists.filter((groupItem) => groupItem.group_id !== group.group_id),
        }
      } else {
        return item;
      }
    })
  },
  setSearchPeopleList(state, payload) {
    if (payload.data) {
      payload.data.peoples && (state.lists = payload.data.peoples?.map((item) => ({
        ...item,
        loading: false,
        isAddOpen: false,
        isSaveOpen: false,
        isContactOpen: false,
        isPhoneOpen: false,
      })));
      payload.data.pagination && (state.total = payload.data.pagination)
    }
  },
  setPeopleExportFields(state, payload) {
    state.peopleExportFields = payload;
  },
  setMultipleSelectedListIds(state, payload) {
    state.multipleSelectedListIds = payload;
  },
  setSelected(state, val) {
    if (val.recall) {
      val = val.info;
    } 
    const { notReset, ...rest} = val;
    let id = this.state.user.currentId.profile_id
    let temp = {
      profile_id: id,
      ...state.selected,
      ...rest,
      page: val.notReset ? val.page: 1
    }
    state.selected = temp
  },
}
const actions = {
  async searchPeopleList(context, val) {
    try {
      context.commit('setLoading', true);
      const result = await people.searchPeopleList(val)
      context.commit('setLoading', false);
      if (result && result.data) {
        context.commit('setSearchPeopleList', result);
        localStorage.getItem('CbApiInfo2') && localStorage.removeItem('CbApiInfo2')
        return result?.data
      }
    } catch (error) {
      context.commit('setLoading', false);
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
