import * as accountAPI from '@/api/account'
import {setAccount,getAccount} from "@/utils/auth";


const state = {
    usage: {},
    account: getAccount() ? JSON.parse(getAccount()) : {},
    subList:JSON.parse(localStorage.getItem('subList')|| '[]'),
    creditCardList: [],
    invoiceList: [],
    profileUsers:{},
    pendingProfileUsers: {},
    fullProfileUsers: [],
}

const mutations = {
    setProfileUsers(state, profileUsers) {
      state.profileUsers = profileUsers;
    },
    setPendingProfileUsers(state, pendingProfileUsers) {
      state.pendingProfileUsers = pendingProfileUsers;
    },
    setProfileFullUsers(state, fullUsers) {
      state.fullProfileUsers = fullUsers
    },
    setUsage(state, usage) {
        state.usage = usage
    },
    setAccount(state, account) {
        account ? state.account = account : state.account = {}
        setAccount(JSON.stringify(state.account))
    },
    setSubList(state, subList) {
        subList ? state.subList = subList : state.subList = []
        localStorage.setItem('subList',JSON.stringify(state.subList))
    },
    setCreditCardList(state, creditList) {
        state.creditCardList = creditList
    },
    setInvoiceList(state, invoiceList) {
        state.invoiceList = invoiceList
    },
}

const actions = {
  async getProfile({ commit, dispatch, state, rootState },data) {
    const { profile_id, dontGetCommonData } = data;
    const res = await accountAPI.getProfile({ profile_id }).catch(err=>{
      throw err;
    })
    commit('setAccount', res?.data)
    if (!dontGetCommonData) {
      dispatch("common/getDepartments",{}, {root: true});
      dispatch("common/getCompanyList", "", {root: true});
      dispatch("common/getDepartments", {}, {root: true});
      dispatch("common/getDepartmentFunctions", {}, {root: true});
      dispatch("common/getLocationList", {}, {root: true});
      dispatch("common/getIndustry", {},{root: true});
      dispatch("common/getIndustryCategory", {},{root: true});
      dispatch('common/getTechList', {}, {root: true});
      dispatch('common/getCategoryList', {}, {root: true});
      dispatch('common/getMediaList', {}, {root: true});
      dispatch('common/getRevenue', {}, {root: true});
      dispatch('common/getCompanySize', {}, {root: true});
      dispatch('common/getManagementLevels', {},{root: true});
      dispatch('common/getAdList', {}, {root: true})
    }
    return res?.data
  },
  async getUsage(context,data) {
    const usage = await accountAPI.getUsage(data).catch(err=>{
      throw err;
    })
    context.commit('setUsage', usage?.data)
    return usage?.data
  },
  async updateUser(context, data) {
    const res = await accountAPI.updateUser(data).catch(err=>{
      console.log(err);
      throw err;
    })
    return res?.data
    context.commit('setAccount', data)
  },
  async updatePassword(context, data) {
    const res = await accountAPI.updatePassword({...data,"access_token":context.rootState.user.token}).catch(err=>{
      console.log(err);
      throw err;
    })
    return res?.data
  },
  /*--------------profile-user-------------------*/
  async getUserList(context,data) {
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
    }
    const res = await accountAPI.getProfileUsers({
      ...params,
      ...data,
    }).catch(err=>{
      console.log(err);
      throw err;
    });
    if (data && data.is_current) {
      context.commit('setProfileUsers', res?.data)
    } else {
      context.commit('setPendingProfileUsers', res?.data)
    }
    return res?.data
  },
  async getFullUserList(context,data) {
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
    }
    const res = await accountAPI.getProfileFullUsers({
      ...params,
      ...data
    }).catch(err=>{
      console.log(err);
      throw err;
    })
    context.commit('setProfileFullUsers', res?.data)
    return res?.data
  },
  async inviteUsers(context,data) {
    const res = await accountAPI.inviteProfileUser(data).catch(err=>{
      console.log(err);
      throw err;
    })
    return res?.data
  },
  async deleteUsers(context,data) {
    const res = await accountAPI.deleteUser(data).catch(err=>{
      console.log(err);
      throw err;
    })
    return res?.data
  },

  /*------------订阅------------------ */
  async getSubscription(context,data) {
    const res = await accountAPI.getSubscription(data).catch(err=>{
      console.log(err);
      throw err;
    })
    context.commit('setSubList', res)
    return res
  },
  /*--订阅grade--*/
  async upgradeSubscription(context, data) {
    const res = await accountAPI.upgradeSubscription(data).catch(err=>{
      console.log(err);
      throw err;
    })
    return res?.data
  },
  /*--购买credit--*/
  async purchaseCredit(context, data) {
    const res = await accountAPI.purchaseCredit(data).catch(err=>{
      console.log(err);
      throw err;
    })
    return res?.data
  },

  /*------------billing------------------*/
  /*--增加信用卡--*/
  async addCreditCard(context, data) {
    const res = await accountAPI.addCredit(data).catch(err=>{
      console.log(err);
      throw err;
    })
  },
  async getCreditCard(context,data) {
    const res = await accountAPI.getPaymentMethods(data).catch(err=>{
      console.log(err);
      throw err;
    })
    context.commit('setCreditCardList', res?.data)
    return res?.data
  },
  /*--更新（删除）信用卡--*/
  async deleteCreditCard(context, data) {
    const res = await accountAPI.deleteCredit(data).catch(err=>{
      console.log(err);
      throw err;
    })
    return res?.data

  },
  /*--获取invoice--*/
  async getInvoice(context,data) {
    const res = await accountAPI.getInvoices(data).catch(err=>{
      console.log(err);
      throw err;
    })
    context.commit('setInvoiceList', res?.data)
    return res?.data
  },
  /*----下载发票----*/
  async downloadInvoice(context, data) {
    const res = await accountAPI.downloadInvoice(data).catch(err=>{
      console.log(err);
      throw err;
    })
    return res?.data
  },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
