import moment from 'moment-timezone';

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}
/** *
 *
 *  将列表型的数据转化成树形数据 => 递归算法 => 自身调用自身 => 一定条件不能一样， 否则就会死循环
 *  遍历树形 有一个重点 要先找一个头儿
 * ***/
export function tranListToTreeData(list, rootValue) {
  var arr = []
  list.forEach(item => {
    if (item.pid === rootValue) {
      // 找到之后 就要去找 item 下面有没有子节点
      const children = tranListToTreeData(list, item.id)
      if (children.length) {
        // 如果children的长度大于0 说明找到了子节点
        item.children = children
      }
      arr.push(item) // 将内容加入到数组中
    }
  })
  return arr
}

export function transLang(value) {
  return this.$te(value) ? this.$t(value): value;
}

export function formatDuration(val,hmsType) {
  if (!!val) {
    const second = val % 60;
    const minute = Math.floor(val / 60);
    if (hmsType) {
      // HH:mm:ss格式
      return minute > 0 ? `00:${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second: second}`: `00:00:${second}`;
    } else {
      return minute > 0 ? minute + 'min' + second + 'secs': second + 'secs';
    }
  } else {
    return '-'
  }
}

export function getDateInfo(val) {
  // const date = new Date(val);
  // const timezoneOffset = date.getTimezoneOffset();
  // let localTime = moment(date).subtract(timezoneOffset, 'm');
  // return localTime;
  return moment.utc(val).local();
}

export function getBrowser() {
  var ua = window.navigator.userAgent
  // var isIE = window.ActiveXObject != undefined && ua.indexOf("MSIE") != -1;
  var isIE = !!window.ActiveXObject || 'ActiveXObject' in window
  var isFirefox = ua.indexOf('Firefox') != -1
  var isOpera = window.opr != undefined
  var isChrome = ua.indexOf('Chrome') && window.chrome
  var isSafari = ua.indexOf('Safari') != -1 && ua.indexOf('Version') != -1
  if (isIE) {
    return 'IE'
  } else if (isFirefox) {
    return 'Firefox'
  } else if (isOpera) {
    return 'Opera'
  } else if (isChrome) {
    return 'Chrome'
  } else if (isSafari) {
    return 'Safari'
  } else {
    return 'Unkown'
  }
}

export function saveFile(blob, fileName) {
  const b = getBrowser()
  if (b == 'Chrome') {
    const link = document.createElement('a')
    const file = new Blob([blob], { type: 'application/vnd.ms-excel' })
    link.href = window.URL.createObjectURL(file)
    link.download = fileName
    link.click()
  } else if (b == 'Firefox') {
    const file = new File([blob], fileName, { type: 'application/vnd.ms-excel' })
    const url = URL.createObjectURL(file)
    window.location.href = url
    // parent.location.href = url
  } else if (b == 'IE') {
    const file = new Blob([blob], { type: 'application/vnd.ms-excel' })
    window.navigator.msSaveBlob(file, fileName)
  }
}

export function exportToCSV(data, cb) {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'data.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  cb && cb();
}


export function saveServiceFile(data, fileName, fileType) {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement('a');
  a.style.display = 'none';

  const url = window.URL.createObjectURL(blob, { type: fileType });
  a.href = url;
  a.download = fileName;

  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}


export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function(event) {
        const base64String = event.target.result;
        resolve(base64String);
    };
    reader.onerror = function(error) {
        reject(error);
    };
    reader.readAsDataURL(file);
  });
}
export function base64ToFile(base64String, name, uid) {
  // 将 Base64 字符串转换为 Blob 对象
  const byteCharacters = atob(base64String.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/octet-stream' });

  // 使用 Blob 对象创建新的 File 对象
  const restoredFile = new File([blob], name, { type: 'application/octet-stream' });
  restoredFile.uid = uid;

  return restoredFile;
}

export function getDate(date) {
  const fullMonthMap = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  }
  if (!date) return '-';
  const localTime = moment.utc(date).local();
  return `${fullMonthMap[localTime.format("MM")]} ${localTime.date()}, ${localTime.year()}`;
}

export function formatAMandPM(val) {
  if (!val) return;
  const date = moment.utc(val).local();
  const hour = date.format("HH");
  return hour >= 12 ? "PM" : "AM";
}

export const debounce = (func, wait) => {
  let delay = wait || 500;
  let timer;
  return () => {
    const _this = this;
    let args = arguments;
    if(timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(_this, args)
    }, delay)
  }
}

export const extractInitials = (name) => {
  if (name.length <= 2) {
    return name.toUpperCase();
  }
  const words = name.split(" ");
  let initials = "";
  for (let i = 0; i < words.length; i++) {
      initials += words[i].charAt(0).toUpperCase();
  }
  return initials;
}

export const getUrlHashAndRecallApi = () => {
  const urlParams = param2Obj(window.location.href)
  if (urlParams.hash) {
    const cbInfo = localStorage.getItem('CbApiInfo');
    if (cbInfo) {
      const { page, name, params } = JSON.parse(localStorage.getItem('CbApiInfo'));
      const tempParams = {
        ...JSON.parse(params),
        hash: urlParams.hash,
      }
      return {
        success: true,
        page,
        name,
        params: tempParams
      }
      // localStorage.removeItem('CbApiInfo');
    }
  } else if (urlParams.status && urlParams.message && urlParams.code) {
    return {
      success: false,
      status: urlParams.status,
      message: urlParams.message,
      code: urlParams.code,
    }
  }
}


