export default {
    //top-nav
    Home: "首页",
    Search: "搜索",
    Lead: "潜在客户",
    Prospects: "潜在客户",
    filterYourSearchToFindHighlySpecific: "过滤您的搜索以找到高度特定的",
    groupsOfTargetedLeads: "目标潜在客户组",
    filterYourSearchToFindPreciselyTargeted: "过滤您的搜索以找到精确定位的",
    listsOfCompanies: "公司列表",
    transformAnonymousWebsiteTrafficInto: "将匿名网站流量转换为",
    b2bLeadsWithRealtimeIdentification: "实时识别的B2B潜在客户",
    discoverAndConnectWithPotentialBuyers: "发现并与潜在客户联系",
    activelySeekingYourSolutionToday: "今天积极寻求您的解决方案",
    Intent_Signals: "意图信号",
    Website_Intent: "网站意图",
    Market_Intent: "市场意图",
    Sequence: "工作流程",
    Tools: "工具",
    Services: "企业服务",
    technologyLookup: "技术查询",
    domainLookup: "域名查询",
    emailVerification: "电子邮件验证",
    upgrade: "升级",
    helpCenter: "帮助中心",
    contactUs: "联系我们",
    feedback: "反馈",
    hello: "你好",
    clickToChange: "点击更改",
    yourProfile: "您的个人资料",
    currentUsage: "当前使用情况",
    upgradePlan: "升级计划",
    integrations: "集成",
    featureRequests: "功能请求",
    change: "更改",
    en: "英文",
    cn: "中文（简）",
    tw: "中文（繁）",
    logout: "登出",
    switchLanguage: "成功切换语言",
};