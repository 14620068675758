import { Message } from "element-ui";
import { getDepartments, getDepartmentFunction, getManagementLevels } from '@/api/people';
import { getCompanyList, getLocationList, getCompanySize, getIndustry, getIndustryCategory, getTechList, getTechCategoryList, getMediaList, getRevenue } from '@/api/company';
import { getSubscriptions, upgradePlan } from "@/api/setting";
import { getAd } from '@/api/website-intent';
import { getUrlHashAndRecallApi } from '@/utils';
import { purchaseCredit } from '@/api/account';


const state = {
  managementLevels: [],
  managementLoading: false,
  departments: [],
  departmentLoading: false,
  departmentFunctions: [],
  departmentFunctionLoading: false,
  companyLists: [],
  originCompanyList:[],
  locationList: [],
  locationLoading: false,
  companySize: [],
  techList: [],
  originTechList: [],
  techCategoryList: [],
  techCategoryLoading: false,
  verticalsList: [],
  originVerticalsList: [],
  verticalsCategoryList: [],
  verticalsCategoryLoading: false,
  mediaList: [],
  mediaLoading: false,
  adList: [],
  adLoading: false,
  maximumQueryLimitShow: false,
  annualPriceList: [],
  monthPriceList: [],
  monthPriceIndex: -1,
  annualPriceIndex: -1,
  payDialog: false,
  paymentSecurityDialog: false,
  notEnoughCredits: false,
  upgradeDialog: false,







  revenueList: [],
}

const mutations = {
  setDepartments(state, payload) {
    state.departments = payload;
  },
  setDepartmentLoading(state, payload) {
    state.departmentLoading = payload;
  },
  setDepartmentFunctions(state, payload) {
    state.departmentFunctions = payload;
  },
  setDepartmentFunctionLoading(state, payload) {
    state.departmentFunctionLoading = payload;
  },
  setManagementLevels(state, payload) {
    state.managementLevels = payload;
  },
  setManagementLoading(state, payload) {
    state.managementLoading = payload;
  },
  setCompanyList(state, payload) {
    state.companyLists = payload;
  },
  setOriginCompanyList(state, payload) {
    state.originCompanyList = payload;
  },
  setLocationList(state, payload) {
    state.locationList = payload;
  },
  setLocationLoading(state, payload) {
    state.locationLoading = payload;
  },
  setCompanySize(state, payload) {
    state.companySize = payload;
  },
  setVerticalsList(state, payload) {
    state.verticalsList = payload;
  },
  setOriginVerticalsList(state, payload) {
    state.originVerticalsList = payload;
  },
  setVerticalsCategoryList(state, payload) {
    state.verticalsCategoryList = payload;
  },
  setTechList(state, payload) {
    state.techList = payload;
  },
  setOriginTechList(state, payload) {
    state.originTechList = payload;
  },
  setTechCategoryList(state, payload) {
    state.techCategoryList = payload;
  },
  setTechCategoryLoading(state, payload) {
    state.techCategoryLoading = payload;
  },
  setVerticalsCategoryLoading(state, payload) {
    state.verticalsCategoryLoading = payload;
  },
  setMediaList(state, payload) {
    state.mediaList = payload;
  },
  setMediaLoading(state, payload) {
    state.mediaLoading = payload;
  },
  setRevenueList(state, payload) {
    state.revenueList = payload;
  },
  setAdList(state, payload) {
    state.adList = payload;
  },
  setAdLoading(state, payload) {
    state.adLoading = payload;
  },
  setMaximumQueryLimitShow(state) {
    state.maximumQueryLimitShow = !state.maximumQueryLimitShow;
  },
  setMonthPriceList(state, payload) {
    state.monthPriceList = payload;
  },
  setAnnualPriceList(state, payload) {
    state.annualPriceList = payload;
  },
  setMonthPriceIndex(state, payload) {
    state.monthPriceIndex = payload
  },
  setAnnualPriceIndex(state, payload) {
    state.annualPriceIndex = payload
  },
  setPayDialog(state, payload) {
    state.payDialog = payload;
  },
  setPaymentSecurityDialog(state, payload) {
    state.paymentSecurityDialog = payload;
  },
  setNotEnoughCredits(state, payload) {
    state.notEnoughCredits = payload;
  },
  setUpgradeDialog(state, payload) {
    state.upgradeDialog = payload;
  }
}

const actions = {
  /*-------------切换语言时获取全部----------------*/
  async getAllSelectList({ commit, dispatch }, data) {
    try {
      dispatch('getDepartments');
      dispatch('getDepartmentFunctions');
      dispatch('getManagementLevels');
      dispatch('getCompanyList');
      dispatch('getLocationList');
      dispatch('getCompanySize');
      dispatch('getIndustry');
      dispatch('getIndustryCategory');
      dispatch('getTechList');
      dispatch('getCategoryList');
      dispatch('getMediaList');
      dispatch('getRevenue');
      dispatch('getAdList')
    } catch (err) {
      console.log(err)
      throw err
    }
  },
  /*-------------人员部门----------------*/
  async getDepartments({commit}) {
    try {
      commit('setDepartmentLoading', true);
      const result = await getDepartments();
      if (result && result.data) {
        commit('setDepartments', result.data)
        localStorage.setItem('departments', JSON.stringify(result.data))
      }
    } catch (err) {
      console.log(err)
      throw err
    } finally {
      commit('setDepartmentLoading', false);
    }
  },
  /*-------------部门Function----------------*/
  async getDepartmentFunctions({commit}) {
    try {
      commit('setDepartmentFunctionLoading', true);
      const result = await getDepartmentFunction()
      if (result?.data) {
        commit('setDepartmentFunctions', result.data)
        localStorage.setItem('departmentFunctions', JSON.stringify(result.data));
      }
    } catch (err) {
      console.log(err)
      throw err
    } finally {
      commit('setDepartmentFunctionLoading', false);
    }
  },
  /*-------------人员管理级别----------------*/
  async getManagementLevels({commit}) {
    try {
      commit('setManagementLoading', true)
      const result = await getManagementLevels()
      if (result && result.data) {
        commit('setManagementLevels', result.data)
        localStorage.setItem('managementLevels', JSON.stringify(result.data))
      }
    } catch (err) {
      console.log(err)
      throw err
    } finally {
      commit('setManagementLoading', false)
    }
  },
  /*---------------推荐公司列表----------------------*/
  async getCompanyList(context, val) {
    try {
      const result = await getCompanyList({ "company_name": val });
      if (result && result.data) {
        context.commit('setCompanyList', result.data);
        if (!val) {
          context.commit('setOriginCompanyList', result.data);
        }
        localStorage.setItem('companyLists', JSON.stringify(result.data))
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getLocationList({ commit }) {
    try {
      commit('setLocationLoading', true);
      const result = await getLocationList();
      if (result && result.data) {
        commit('setLocationList', result.data);
        localStorage.setItem('locationList', JSON.stringify(result.data))
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      commit('setLocationLoading', false);
    }
  },
  async getCompanySize(context) {
    try {
      const result = await getCompanySize();
      if (result && result.data) {
        context.commit('setCompanySize', result.data);
        localStorage.setItem('companySize', JSON.stringify(result.data))
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getIndustry(context, data) {
    try {
      const result = await getIndustry(data);
      if (result && result.data) {
        context.commit('setVerticalsList', result.data);
        if (!data?.keyword) {
          context.commit('setOriginVerticalsList', result.data);
        }
        localStorage.setItem('verticalsList', JSON.stringify(result.data))
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getIndustryCategory({ commit }) {
    try {
      commit('setVerticalsCategoryLoading', true);
      const result = await getIndustryCategory();
      if (result && result.data) {
        commit('setVerticalsCategoryList', result.data);
        localStorage.setItem('verticalsCategoryList', JSON.stringify(result.data))
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      commit('setVerticalsCategoryLoading', false);
    }
  },
  async getTechList(context, data) {
    try {
      const result = await getTechList(data);
      if (result && result.data) {
        context.commit('setTechList', result.data);
        if (!data?.keyword) {
          context.commit('setOriginTechList', result.data);
        }
        localStorage.setItem('techList', JSON.stringify(result.data))
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getCategoryList({commit}, data) {
    try {
      commit('setTechCategoryLoading', true);
      const result = await getTechCategoryList(data);
      if (result && result.data) {
        commit('setTechCategoryList', result.data);
        localStorage.setItem('techCategoryList', JSON.stringify(result.data))
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      commit('setTechCategoryLoading', false);
    }
  },
  async getMediaList({ commit }) {
    try {
      commit('setMediaLoading', true);
      const result = await getMediaList();
      if (result && result.data) {
        commit('setMediaList', result.data);
        localStorage.setItem('mediaList', JSON.stringify(result.data))
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      commit('setMediaLoading', false);
    }
  },
  async getRevenue(context) {
    try {
      const result = await getRevenue();
      if (result && result.data) {
        context.commit('setRevenueList', result.data)
        localStorage.setItem('revenueList', JSON.stringify(result.data))
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getAdList({ commit }) {
    try {
      commit('setAdLoading', true);
      const result = await getAd();
      if (result && result.data) {
        commit('setAdList', result.data);
        localStorage.setItem('adList', JSON.stringify(result.data))
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      commit('setAdLoading', false);
    }
  },
  async getSubscription({ commit }) {
    // this.loading = true
    const data = await getSubscriptions({
      profile_id: this.state.user.currentId.profile_id,
    });
    const monthList = [];
    const yearList = [];
    data?.data?.map((item) => {
      if (item.is_default || item.is_custom_plan) {
        yearList.push(item);
        monthList.push(item);
      } else {
        if (item.is_annual) {
          item.is_current && commit('setAnnualPriceIndex', yearList.length)
          yearList.push(item)
        } else {
          item.is_current && commit('setMonthPriceIndex', monthList.length)
          monthList.push(item)
        }
      }
    });
    commit('setAnnualPriceList', yearList)
    commit('setMonthPriceList', monthList)
    // this.loading = false
  },
  recallApi({ commit }, payload) {
    const data = getUrlHashAndRecallApi();
    if (data) {
      if(data.success) {
        if (data.page === payload.path) {
          payload.cb && payload.cb(data.name, data.params, true)
        }
      } else if (data.message) {
        Message.error(data.message);
        localStorage.getItem('CbApiInfo') && localStorage.removeItem('CbApiInfo')
      }
      return true;
    } else {
      return false;
    }

  },
  async changePlan({ commit, state }, data) {
    try {
      const params = {
        profile_id: this.state.user.currentId.profile_id,
        subscription_identifier: data?.info?.subscription_identifier,
        redirect_path: '/#' + data?.info.path,
      }
      const result = await upgradePlan(data.recall ? data.info: params);
      Message.success('change successfully');
      data.recall && localStorage.getItem('CbApiInfo') && localStorage.removeItem('CbApiInfo');
      return result;
    } catch(err) {
      if (err.response && err.response.data.code) {
        if (err.response.data.code === 40208) {
          commit('setPaymentSecurityDialog', true)
        } else if (err.response.data.code === 40201) {
          if (state.maximumQueryLimitShow) {
            commit('setMaximumQueryLimitShow', false);
          }
          // commit('setPayDialog', true) // request里处理了
        } else if (err.response.data.code === 40210) {
          localStorage.setItem('CbApiInfo', JSON.stringify({
            page: data?.info.path,
            name: 'changePlan',
            params: err.response.config.data
          }));
          data.cb && data.cb();
          window.location.href = err.response.data.details.next_action.redirect_to_url;
        } else {
          // 40211: "The transaction has been completed before"
          // 40901
          Message.error(err.response.data);
        }
      }
    }
  },
  async topUp({ commit, state }, data) {
    try {
      const params = {
        profile_id: this.state.user.currentId.profile_id,
        credits: this.state.user.systemConfig.min_credit_topup,
        redirect_path: '/#' + data?.info.path,
      }
      const result = await purchaseCredit(data.recall ? data.info: params);
      Message.success('topup successfully');
      data.recall && localStorage.getItem('CbApiInfo') && localStorage.removeItem('CbApiInfo');
      return result;
    } catch(err) {
      if (err.response.data.code === 40210) {
        localStorage.setItem('CbApiInfo', JSON.stringify({
          page: data?.info.path,
          name: 'topUp',
          params: err.response.config.data
        }));
        data.cb && data.cb();
        window.location.href = err.response.data.details.next_action.redirect_to_url;
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
