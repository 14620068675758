import * as ComObj from "@/api/company";

const state = {
  selected: {
    locations: [],
    verticals: [],
    vertical_categories: [],
    categories: [],
    technologies: [],
    company_groups: [],
    companies: [],
    social_media: [],
    company_name: '',
    employees: [],
    founded_dates: [],
    revenues: [],
    page: 1,
    per_page: parseInt(process.env.VUE_APP_PER_PAGE),
  },
  savedSearchesPeopleFilter: {
    saved_search_name: '',
    is_ascending_order: false,
    is_personal: null,
  },
  savedSearchesCompanyFilter: {
    saved_search_name: '',
    is_ascending_order: false,
    is_personal: null,
  },
  savedSearchesIntentFilter: {
    saved_search_name: '',
    is_ascending_order: false,
    is_personal: null,
  },
  peopleSavedSearchesList: [],
  companySavedSearchesList: [],
  intentSavedSearchesList: [],
  checkedList: [],
  isShow: true, //控制filter是否隐藏
  isLoading: false,
  searchCompanyList: [],//搜索公司列表
  total: {},
  companyExportFields: [],
  companySelectedModule: '',
  multipleSelectedListIds: [], // multiple select ids
  savedList: {},





  isStopSearch: false,//控制是否停止搜索
  addCreditsVisible: false,//控制是否显示添加credits弹窗
}

const mutations = {
  /*----------------已确认过代码部分开始---------*/
  setCompanySelectedModule(state, payload) {
    state.companySelectedModule = payload
  },
  setCollapse(state) {
    state.isShow = !state.isShow
  },
  setLoading(state, val) {
    state.isLoading = val
  },
  setCheckedList(state, payload) {
    state.checkedList = payload;
  },
  setSavedList(state, payload) {
    state.savedList = payload;
  },
  setIntentSavedSearchesFilter(state, payload) {
    state.savedSearchesIntentFilter = {
      ...this.savedSearchesIntentFilter,
      ...payload
    };
  },
  setPeopleSavedSearchesFilter(state, payload) {
    state.savedSearchesPeopleFilter = {
      ...this.savedSearchesPeopleFilter,
      ...payload
    };
  },
  setCompanySavedSearchesFilter(state, payload) {
    state.savedSearchesCompanyFilter = {
      ...this.savedSearchesCompanyFilter,
      ...payload,
    };
  },
  setCompanySavedSearchesList(state, savedList) {
    state.companySavedSearchesList = savedList;
  },
  setPeopleSavedSearchesList(state, savedList) {
    state.peopleSavedSearchesList = savedList;
  },
  setIntentSavedSearchesList(state, savedList) {
    state.intentSavedSearchesList = savedList;
  },
  setMultiCompanyAddSavedList(state, addedGroupInfo) {
    const ids = state.checkedList.map((item) => item.domain_search_id);
    state.searchCompanyList = state.searchCompanyList.map((item) => {
      if (ids.includes(item.domain_search_id)) {
        if (item.saved_lists) {
          const groupIds = item.saved_lists.map((group) => group.group_id);
          return groupIds.includes(addedGroupInfo.group_id) ? item : { 
            ...item,
            saved_lists: [...item.saved_lists, addedGroupInfo],
          }
        } else {
          return {
            ...item,
            saved_lists: [addedGroupInfo]
          }
        }
      } else {
        return item;
      }
    })
  },
  setCompanyRowAddSavedList(state, payload) {
    const { domain_search_id, group } = payload;
    state.searchCompanyList = state.searchCompanyList.map((item) => {
      if (item.domain_search_id === domain_search_id) {
        if (item.saved_lists) {
          const groupIds = item.saved_lists.map((groupItem) => groupItem.group_id);
          return groupIds.includes(group.group_id) ? item : { 
            ...item,
            saved_lists: [...item.saved_lists, group],
          }
        } else {
          return {
            ...item,
            saved_lists: [group],
          }
        }
      } else {
        return item;
      };
    })
  },
  setMultiCompanyDeleteSavedList(state, groupInfo) {
    const ids = state.checkedList.map((item) => item.domain_search_id);
    state.searchCompanyList = state.searchCompanyList.map((item) => {
      return ids.includes(item.domain_search_id) ? {
        ...item,
        saved_lists: item.saved_lists.filter((groupItem) => groupItem.group_id !== groupInfo.group_id),
      } : item;
    })
  },
  setCompanyRowDeleteSavedList(state, payload) {
    const { domain_search_id, group } = payload;
    state.searchCompanyList = state.searchCompanyList.map((item) => {
      if (item.domain_search_id === domain_search_id) {
        return {
          ...item,
          saved_lists: item.saved_lists.filter((groupItem) => groupItem.group_id !== group.group_id),
        }
      } else {
        return item;
      }
    })
  },
  setCompanyExportFields(state, payload) {
    state.companyExportFields = payload;
  },
  setSelected(state, val) {
    let id = this.state.user.currentId.profile_id;
    const { notReset, ...rest} = val;
    let temp = { 
      profile_id: id,
      ...state.selected,
      ...rest,
      page: val.notReset ? val.page: 1
    }
    if (_.isEqual(state.selected, temp)) {
      // state.isStopSearch = true
    } else {
      state.selected = temp
    }
  },
  setSearchCompanyList(state, payload) {
    const data = payload?.data
    if (data) {
      data.companies && (state.searchCompanyList = data.companies?.map((item) => ({
        ...item,
        loading: false,
        isAddOpen: false,
        isSaveOpen: false,
        isContactOpen: false
      })));
      data.pagination && (state.total = data.pagination)
    }
  },
  setMultipleSelectedListIds(state, payload) {
    state.multipleSelectedListIds = payload;
  },
  /*----------------已确认过代码部分结束---------*/
}

const actions = {
  async searchCompanyList(context, val) {
    try {
      context.commit('setLoading', true);
      const result = await ComObj.searchCompanyList(val);
      context.commit('setLoading', false);
      context.commit('setSearchCompanyList', result);
      localStorage.getItem('CbApiInfo2') && localStorage.removeItem('CbApiInfo2')
      return result;
    } catch (err) {
      context.commit('setLoading', false);
      console.log(err);
      // throw err;
    }
  },
  /*----------------------------handle list-----------------------------------*/
  async addCompanyToGroup(context, val) {
    try {
      await ComObj.addCompany(val);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async deleteCompany(context, val) {
    try {
      const result = await ComObj.deleteCompany({ ...val, profile_id: this.state.user.currentId.profile_id });
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /*--------------获取公司详情--------------------*/
  async getCompanyDetail(context, data) {
    try {
      const result = await ComObj.getCompanyDetail(data);
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /*-----根据groups返回domain ID------*/
  async getDomainIdByGroup(context, data) {
    try {
      const result = await ComObj.getDomainIdByGroup({ ...data, profile_id: this.state.user.currentId.profile_id });
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /*---------------export------------------*/
  async export(context, data) {
    try {
      const result = await ComObj.exportCompany({ ...data, profile_id: this.state.user.currentId.profile_id });
      return result;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  /*---------------saved search------------------*/
  async getSaveSearchesList(context, data) {
    try {
      const { search_type_id } = data;
      const result = await ComObj.getSaveList({
        profile_id: this.state.user.currentId.profile_id,
        ...data,
      });
      if (search_type_id === 1) {
        context.commit('setCompanySavedSearchesList', result.data ? result.data : []);
      } else if (search_type_id === 2) {
        context.commit('setPeopleSavedSearchesList', result.data ? result.data : []);
      } else if (search_type_id === 3) {
        context.commit('setIntentSavedSearchesList', result.data ? result.data : []);
      }
      return result?.data;
    } catch (err) {
      throw err;
    }
  },
  async addSaveList(context, data) {
    try {
      let type = data?.search_type_id === 1 ? this.state.company.selected : this.state.people.selected;
      let params = data?.params ? data?.params : type;
      await ComObj.addSaveList({
        profile_id: this.state.user.currentId.profile_id,
        saved_search_name: data?.saved_search_name,
        is_personal: data?.is_personal,
        search_type_id: data?.search_type_id,
        is_pinned: data?.is_pinned,
        parameters: params
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async updateSaveList(context, data) {
    try {
      let payload = {};
      if (data?.params) {
        payload = {
          profile_id: this.state.user.currentId.profile_id,
          saved_searches: data?.saved_searches,
          saved_search_name: data?.saved_search_name,
          is_personal: data?.is_personal,
          is_pinned: data?.is_pinned,
          parameters: data?.params,
        };
      } else {
        payload = {
          profile_id: this.state.user.currentId.profile_id,
          saved_searches: data?.saved_searches,
          saved_search_name: data?.saved_search_name,
          is_personal: data?.is_personal,
          is_pinned: data?.is_pinned,
          parameters: data?.search_type_id === 1 ? this.state.company.selected : this.state.people.selected
        };
      }
      let result = await ComObj.updateSaveList(payload);
      return result?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
