export default {
    Home: "Home",
    Search: "Search",
    Lead: "Lead",
    Prospects: "Prospects",
    filterYourSearchToFindHighlySpecific: "Filter your search to find highly specific",
    groupsOfTargetedLeads: "groups of targeted leads",
    filterYourSearchToFindPreciselyTargeted: "Filter your search to find precisely targeted",
    listsOfCompanies: "lists of companies",
    transformAnonymousWebsiteTrafficInto: "Transform anonymous website traffic into",
    b2bLeadsWithRealtimeIdentification: "B2B leads with real-time identification",
    discoverAndConnectWithPotentialBuyers: "Discover and connect with potential buyers",
    activelySeekingYourSolutionToday: "actively seeking your solution today",
    Intent_Signals: "Intent",
    Website_Intent: "Website Intent",
    Market_Intent: "Market Intent",
    Sequence: "Sequence",
    Tools: "Tools",
    Services: "Services",
    technologyLookup: "Technology Lookup",
    domainLookup: "Domain Lookup",
    emailVerification: "Email Verification",
    upgrade: "Upgrade",
    helpCenter: "Help Center",
    contactUs: "Contact Us",
    feedback: "Feedback",
    hello: "Hello",
    clickToChange: "Click to change",
    yourProfile: "Your Profile",
    currentUsage: "Current Usage",
    upgradePlan: "Upgrade Plan",
    integrations: "Integrations",
    featureRequests: "Feature Requests",
    change: "change",
    en: "English",
    cn: "中文（简）",
    tw: "中文（繁）",
    logout: "Log Out",
    switchLanguage: "Switched Language successfully",
};