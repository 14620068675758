export default {
  serviceMenuOverview: "概览",
  serviceMenuTeams: "团队",
  serviceMenuPipeline: "管道",
  serviceMenuAnalytics: "分析",
  serviceMenuResources: "资源",
  salesServices: "企业服务",
  chooseYourTypeofServicesToStreamlineAndEnhanceYourBusinessOperations:
    "选择您的服务类型，以简化和增强您的业务运营",
  dataPreparation: "数据处理",
  salesAssistant: "销售助手",
  appointmentSetting: "预约设置",
  othersCustomService: "其他（自定义服务）",
  requestService: "请求服务",
  learnMore: "了解更多",
  serviceRequestStatus: "服务请求状态",
  noServiceRequestsYet: "尚无服务请求",
  submitYourFirstRequestToUnlockThePower:
    "提交您的第一次请求，以解锁我们销售服务的力量！在此跟踪您的请求状态，提升您的销售工作。让我们开始吧！",
  serviceTableItemName: "名称",
  serviceTableItemStatus: "状态",
  serviceTableItemType: "类型",
  serviceTableItemProgress: "进度",
  serviceTableItemStart: "开始时间",
  serviceTableItemEnd: "预计结束时间",
  serviceTableItemPriority: "优先级",
  addASubject: "添加主题",
  created: "已创建",
  closed: "已关闭",
  paused: "已暂停",
  working: "工作中",
  completed: "已完成",
  resuming: "恢复中",
  reviewing: "审核中",
  others: "其他",

  data_preparation: "数据处理",
  sales_assistant: "销售助手",
  appointment_setting: "预约设置",
  appointment_booking: "预约预订",
  low: "低",
  normal: "正常",
  urgent: "紧急",
  high: "高",
  requiredCredits: "所需信用点数",
  serviceCreated: "已创建",
  QandARecord: "问答记录",
  serviceAttachments: "附件",
  discussionBoard: "讨论板",
  wannaAddSomething: "想添加一些东西吗？",
  serviceEdit: "编辑",
  serviceDelete: "删除",
  addDescription: "添加描述",
  DragDropor: "拖放或",
  chooseFile: "选择文件",
  toUpload: "上传",
  ourDataPreparationService:
    "我们的数据处理服务提供全面的解决方案，旨在优化您的销售数据管理。我们专注于简化流程，使您能够增强现有数据集，通过数据清理确保准确性，并创建完全符合您需求的自定义列表。这种方法使您获得灵活性和效率，以有效管理您的数据。",
  moreoverWithOurDedicatedDataManagement:
    "此外，通过我们专门的数据管理服务Pubrio，过程变得更加轻松。您可以无缝地附加文件并增强您的数据集，添加额外信息。Pubrio不仅提供增强功能，还可以根据您的具体标准创建全新的列表。这项个性化服务确保您的数据集精确符合您的目标和行业标准，让您掌控自己的数据环境。",
  flexibilityToAttach:
    "附加现有列表的灵活性：或指示Pubrio根据指定标准查找新列表。",
  comprehensiveDataEnrichment:
    "全面的数据增强：添加缺失的详细信息，增强数据集的完整性。",
  dataCleansingServicesToEnsureAccuracy:
    "数据清理服务以确保准确性：包括删除重复项和错误修正，以确保准确性和质量。",
  abilityToInstructPubrio:
    "指示Pubrio的能力：根据精心定义的标准策划全新的列表。",
  tailoringDatasetsPrecisely: "精确调整数据集：使其与特定目标和行业标准对齐。",
  dataEnrichmentWithExistingList:
    "使用现有列表进行数据增强：轻松通过将当前列表上传到Pubrio来增强您的列表。我们将填补缺失的详细信息，例如电子邮件地址和电话号码，以满足您的要求。例如，提供您的客户数据库，我们将用必要的信息更新它。",
  customListGenerationIf:
    "自定义列表生成：如果您没有列表，让Pubrio为您查找一个。只需告诉我们您的标准，如职位或行业，我们将创建完美的列表。例如，如果您需要特定区域内顶级技术高管的名单，我们将为您处理。",
  dataCleansingForExistingLists:
    "现有列表的数据清理：通过Pubrio的数据清理服务确保您的数据准确可靠。将您的列表发送给我们，我们将删除重复项并修正任何错误。例如，如果您有一份最近潜在客户的名单，我们将确保它为您的营销活动做好准备。",
  empoweringYourSalesEeavors:
    "通过我们的销售助手服务，赋能您的销售努力，我们提供全面支持，涵盖多个关键领域，以确保您的外展和沟通策略精心调整以取得成功。无论您是想创作引人入胜的内容、无缝组织活动、开发量身定制的演示文稿，还是完善销售和沟通策略，我们专门的团队都在这里帮助您提升销售工作并提高品牌在市场上的存在感。",
  craftingCompellingContent:
    "创作引人入胜的内容：有效的沟通是吸引受众注意力的关键。我们的团队擅长创作针对您的品牌和受众偏好的引人入胜内容。从引人深思的LinkedIn帖子到信息丰富的博客文章和引人注目的社交媒体更新，我们确保您的信息与目标市场共鸣。",
  seamlessEventAndWebinarPreparation:
    "无缝的活动和网络研讨会准备：活动和网络研讨会是与受众互动和生成潜在客户的强大工具。我们的销售助手服务处理所有细节，从计划和推广到执行。无论是举办信息丰富的网络研讨会还是组织网络活动，我们都确保您的活动留下深刻印象。",
  tailoredContentDevelopmentAndPresentation:
    "量身定制内容开发和演示：用引人入胜的演示和个性化的信息吸引受众。我们的团队专门创建视觉吸引人的演示文稿并制作针对特定受众细分市场的信息。无论您是在向投资者推介还是培养潜在客户，我们都确保您的内容脱颖而出。",
  strategicSalesAndCommunicationFrameworks:
    "战略销售和沟通框架：通过战略指导和有效的沟通脚本简化销售流程。我们的销售助手服务为您的团队提供推动转化和促进有意义联系所需的工具和策略。",
  craftingCompellingContent: "创作引人入胜的内容",
  companyLinkedInPosts: "公司 LinkedIn 帖子（频率：每周或每月）",
  personalLinkedInPosts:
    "个人 LinkedIn 帖子（频率：灵活，根据偏好和内容可用性）",
  websiteBlogPosts: "网站博客文章（频率：通常每周或每两周）",
  socialMediaPosts: "社交媒体帖子（频率：每日或根据内容策略）",
  emailMarketingCampaigns: "电子邮件营销活动（频率：每周或每两周）",
  seamlessEventAndWebinarPreparation: "无缝的活动和网络研讨会准备",
  webinarAndWorkshopPreparation: "网络研讨会和工作坊准备",
  networkingAndOfflineEvent: "网络和线下活动协调",
  tailoredContentDevelopment: "量身定制的内容开发和呈现",
  companyDeckPreparation: "公司简报准备（用于演示、推介等）",
  highlyPersonalizedMessage: "高度个性化的消息创建（针对特定受众群体量身定制）",
  strategicSales: "战略销售和沟通框架",
  salesCallScripts: "销售电话脚本",
  emailOutreachTemplate: "电子邮件外展模板",
  salesChannelStrategies: "销售渠道策略",
  ourAppointmentSettingService:
    "我们的预约安排服务旨在提升您的业务，促进关键联系，增强行业曝光，并培养有价值的潜在客户。我们专注于个性化的行业曝光、无缝的研讨会和网络研讨会协调、热络新潜在客户，以及对新受众的吸引性接触。",
  individualIndustryAppointment: "个别行业预约曝光和反应",
  tailoredOutreach:
    "量身定制的外展策略针对行业内的关键决策者和影响者，确保您的品牌获得精准曝光。",
  strategicFollowups:
    "战略性跟进帮助我们评估潜在客户的反应、解决他们的疑虑，并促进更深入的互动。",
  eachInteraction:
    "每次互动都根据收件人的特征和偏好进行个性化设计，以最大化影响力。",
  seminarWebinarCoordination: "研讨会与网络研讨会协调",
  ourExpertTeamHandles:
    "我们的专业团队负责所有研讨会和网络研讨会的协调工作，从最初的规划到活动结束后的跟进。",
  weMeticulouslyPlan:
    "我们精心策划和执行每一场活动，展示您的专业知识，确保参与者获得流畅而引人入胜的体验。",
  byLeveragingAdvancedTechnologyPlatforms:
    "通过利用先进的技术平台，我们增强互动性和参与度，最大化活动的影响力。",
  newProspectWarmUp: "新潜在客户预热",
  proactiveEngagementStrategies:
    "采用主动参与策略来热身新潜在客户，为成功的商业对话铺平道路。",
  tailoredCommunicationStrategies:
    "部署量身定制的沟通策略，以建立良好的关系、信任感和对您产品的兴趣。",
  throughStrategicNurturing:
    "通过战略性培养，我们保持潜在客户的参与度，为转化做好准备，提高成功预约的机会。",
  newAudienceApproach: "新受众接触策略",
  weEmployInnovativeStrategies:
    "我们采用创新策略来接触新受众，扩大您的业务范围，超越传统渠道。",
  targetedOutreachCampaigns:
    "根据深入的受众研究和细分制定针对性的外展活动，以确保相关性和效果。",
  ourApproachIsContinuouslyOptimized:
    "我们的方法根据反馈和见解不断优化，确保我们在接触和吸引新受众方面始终走在前列。",
  individualIndustry: "个别行业会议预约及跟进",
  personalizedAppointmentSetting:
    "与行业利益相关者进行个性化预约安排，以促进商机。",
  strategicFollowupCommunication:
    "进行战略性跟进沟通以促进进一步参与，增强转化率。",
  comprehensivePlanning: "全面规划和执行活动，以确保每个细节都能达成预期效果。",
  utilizationOfAdvancedTechnologies: "利用先进技术增强互动性。",
  newProspectWarmUp: "新潜在客户预热",
  proactiveEngagementStrategy: "针对潜在客户的主动参与策略。",
  customizedCommunication: "量身定制的沟通以保持兴趣。",
  newAudienceApproach: "新受众接触策略",
  innovativeOutreach: "为新受众设计的创新外展活动。",
  continuousRefinement: "根据反馈和见解不断完善。",
  customService: "定制服务",
  tailoredToMeet:
    "我们的定制服务旨在满足您的独特需求，提供灵活性和适应性，以应对各种要求。无论您需要协助进行网络研讨会参与者追踪、线下活动推广、广告增强或其他专业任务，我们的专业团队都能高效且有效地帮助您实现目标。",
  webinarParticipantsTracking: "网络研讨会参与者追踪",
  comprehensiveTrackingOfWebinar:
    "全面追踪网络研讨会参与者，以分析参与程度并收集有价值的见解。",
  customizedReportsAndAnalytics:
    "量身定制的报告和分析，以评估网络研讨会的有效性并优化未来策略。",
  seamlessIntegration: "与现有系统无缝集成，以简化数据管理和报告。",
  offlineEventPromotion: "线下活动推广",
  strategicPlanning: "战略规划和执行线下活动推广活动，以最大化出席率和参与度。",
  utilizationOfTargetedMarketingChannels:
    "利用针对性的营销渠道和推广策略，有效接触目标受众。",
  creativeApproaches:
    "根据每个活动的独特特征量身定制创意方法，确保最大影响力和投资回报率。",
  adsEnhancement: "广告增强",
  optimizationOfAdCampaigns:
    "优化各平台上的广告活动，以提高可见性、参与度和转换率。",
  ABTesting: "A/B测试和性能分析，以识别最有效的广告创意、信息传递和目标策略。",
  continuousMonitoring:
    "持续监控和调整广告活动，以适应市场动态变化并最大化结果。",
  customizedSolutions: "定制解决方案",
  ourCustomService:
    "我们的定制服务超越预定义功能，旨在满足您的具体需求和偏好。",
  whetherYouNeedAssistance:
    "无论您需要数据分析、内容创建、战略规划或业务其他方面的协助，我们都能根据您的需求量身定制服务。",
  ourFlexibleApproach:
    "我们灵活的方法使我们能够与您密切合作，开发定制解决方案，实现可见成果并推动业务增长。",
  setupAndImplementation: "网络研讨会参与者追踪系统的设置和实施。",
  customizedReporting:
    "量身定制的报告和分析，以衡量网络研讨会的表现和观众参与度。",
  developmentAndExecution: "针对性的线下活动推广活动的开发和执行。",
  coordinationOfPromotionalActivities:
    "推广活动的协调，包括印刷材料、直邮和地方广告。",
  evaluationAndOptimization: "对数字平台上的广告活动进行评估和优化。",
  creativeDevelopment: "创意开发、目标细化和性能监控，以最大化投资回报率。",
  tailoredServices: "量身定制的服务，以满足特定需求和目标。",
  collaborationWithOurTeam:
    "与我们的团队合作，开发满足您独特需求的定制解决方案。",
  serviceGetStarted: "开始使用",
  keyFeatures: "主要特点",
  dataEnrichment: "数据增强",
  dataCleansing: "数据清理",
  customListGeneration: "自定义列表生成",
  serviceOfferings: "服务项目",
  introDataPreparation:
    "我们的数据处理服务提供全面的解决方案，旨在优化您的销售数据管理。我们专注于简化流程，使您能够增强现有数据 ...",
  introSalesAssitant:
    "销售助手服务超越了一般的行政支持，专注于销售过程中不可或缺的任务。无论您是想创作引人入胜的内容 ...",
  introAppointmentSetting:
    "这项服务采取个性化和实地的方法，根据提供的信息将客户与其目标受众联系，增强行业曝光，并培养 ...",
  introOtherService:
    "'其他'服务允许客户通过提交有关其独特需求的详细信息来请求定制服务，提供灵活性和适应性，以应对各种要求 ...",

  questionCommonPagePriorityLevelTitle: "您对此任务的优先级别是什么？",
  questionCommonPagePriorityLevelDesc:
    "说明您的任务优先级有助于我们及时满足您的需求。您可以在提交后更改它。",
  questionCommonPageTimelineTitle: "考虑订阅期限或时间表吗？",
  questionCommonPageTimelineDesc:
    "告诉我们您首选的时间框架以及计划开始的时间。",
  questionCommonPageServiceRequestTitle:
    "准备开始了吗？首先，给您的申请一个独特的名称",
  questionCommonPageServiceRequestDesc:
    "我们将用它来跟踪并在您的仪表板上更新进度。",
  questionCommonPageServiceDurationTitle: "您的服务期将持续多久？",
  quesitonCommonPageInvestmentBudget:
    "您每月在业务发展和项目上的投资预算是否超过HKD 12000？",
  questionCommonPageHearAboutUs: "您从哪里听说我们的？",
  questionCommonPageUploadTitle:
    "请在此处上传您的Excel/CSV文件以进行数据增强或数据清理",
  questionCommonPageUploadDesc:
    "如果您选择创建自定义列表生成，可以跳过或点击'下一步'而不上传。",
  questionDataPreparationPageInterestedDataOptimizationTitle:
    "您对哪些服务感兴趣以进行数据优化？",
  questionDataPreparationPageInterestedDataOptimizationDesc:
    "您需要在下一步中附加一个CSV文件，以选择数据增强或数据清理。",
  questionDataPreparationPageDataAttributesTitle:
    "选择您希望纳入文件的特定数据属性（如适用）",
  questionDataPreparationPageDataAttributesDesc: "选择一个或多个",
  questionDataPreparationPageNumberOfLeadsTitle:
    "您希望为您的业务生成或获取多少潜在客户？",
  questionDataPreparationPageNumberOfLeadsDesc:
    "指定数量或范围，以便我们为您定制服务。",
  website_url: "网站网址",
  contact_first_name: "联系人名字",
  contact_last_name: "联系人姓氏",
  contact_title: "联系人职称",
  contact_location: "联系人地点",
  contact_work_email: "联系人工作电子邮件",
  contact_personal_email: "联系人个人电子邮件",
  contact_work_phone: "联系人工作电话",
  contact_personal_phone: "联系人个人电话",
  company_size: "公司规模",
  company_industry: "公司行业",
  company_location: "公司地点",
  company_founded_year: "公司成立年份",
  company_keywords: "公司关键字",
  company_ranking_in_global: "公司在全球的排名",
  company_financial_status: "公司财务状况",
  company_office_address: "公司办公地址",
  website_total_visit: "网站总访问量",
  company_funding_status: "公司融资状况",
  slugServiceName: "服务名称",
  slugServiceDesc: "服务描述",
  errEnterServiceName: "请输入服务名称",
  errEnterDescription: "请输入服务描述",
  errSelectAService: "请选择一项服务",
  errSelectSpecificDataAttributes: "请选择特定数据属性",
  errInputTheQuantity: "请输入数量",
  errSelectPriorityLevel: "请选择您的优先级别",
  errSelectTimeline: "请选择时间表",
  errInputContent: "请输入内容",
  errInvestmentBudget: "请选择是或否",
  errServiceDuration: "请选择服务期限",
  errHearAboutUs: "请选择来源",

  placeHolderEnterServiceName: "输入服务名称",
  placeHolderEnterServiceDescription: "输入服务描述",
  placeHolderEnterListGeneration: "输入列表生成",
  placeHolder150TO250: "150 - 250",
  Submit: "提交",
  serviceDownload: "下载",
  serviceExample: "范例",
  forYourReference: "供您参考",
  yourFileNeedsToInclude: "您的文件需要至少包括网站或公司名称",
  includeAll: "包括所有",
  serviceNext: "下一步",
  serviceBack: "返回",
  data_enrichment: "数据增强",
  data_cleansing: "数据清理",
  custom_list_generation: "自定义列表生成",
  job_title: "职位名称",
  timeline_within_one_weeks: "时间范围在一周内",
  timeline_one_to_two_weeks: "时间范围一到两周内",
  timeline_two_to_four_weeks: "时间范围两到四周内",
  timeline_recurring: "定期时间范围",
  timeline_custom_range: "自定义时间范围",
  hear_about_us_social_media: "社交媒体",
  hear_about_us_google: "Google",
  hear_about_us_online_article: "在线文章",
  hear_about_us_webinar: "网络研讨会",
  hear_about_us_referral: "推荐",
  hear_about_us_custom_input: "其他",
  during_last_than_one_month: "少于一个月",
  during_one_to_three_months: "一到三个月",
  during_over_three_months: "超过三个月",
  during_decide_later: "稍后决定",
  yes: "是",
  no: "否",
  byCancelingYouCanEnsureThatNoTasksWillBeClosed:
    "通过取消，您可以确保没有任务将被关闭",
  areYouSureYouWantToCloseThisTask: "您确定要关闭此任务吗？",
  onceTheTaskIsClosed: "一旦任务关闭，将无法撤销，您将无法恢复它。",
  Close: "关闭",
  ourTeamIsWorkingOnThisTask: "我们的团队正在处理此任务",
  pleaseProviceYourReasonForThisAction: "请提供您此行动的理由",
  sendRequest: "发送请求",
  OK: "确定",
  areYouSureYouWantToDelete: "您确定要删除吗？",
  proceedingWithDeletionWillResultInPermanentRemovalOfTheFile:
    "进行删除将导致文件永久删除。",
  byCancelYouEnsureThatNoFileWillBeDeleted:
    "通过取消，您可以确保不会删除任何文件。",
  areYouSureYouWantToDeleteThis: "您确定要删除这个吗？",
  serviceMessage: "消息",
  serviceFile: "文件",

  proceedingWithDeletionWillResultInPermanentRemovalOfThisMessage:
    "进行删除将导致此消息永久删除。",
  proceedingWithDeletionWillResultInPermanentRemovalOfThisFile:
    "进行删除将导致此文件永久删除。",
  youEnsureThatNoMessagesWillBeDeleted:
    "通过取消，您可以确保不会删除任何消息。",
  youEnsureThatNoFilessWillBeDeleted: "通过取消，您可以确保不会删除任何文件。",
  serviceDeleteFail: "删除失败",
  enterYourReason: "输入您的理由",
  seriveStartDate: "开始日期",
  serviceEndDate: "预计结束日期",
  yourRequestHasBeenSent: "您的请求已发送",
  sendUsYourFeedback: "发送您的反馈给我们",
  weAreConstantlyImproving: "我们不断改进！分享您的反馈以塑造我们平台的未来。",
  descriptionYourExperienceHere: "在此描述您的体验...",
  additionallyYouCanAttachAnyScreenshots:
    "此外，您可以附加任何截图或文件以提供更多细节。",
  sendFeedback: "发送反馈",
  serviceResume: "恢复",
  yourRequestHasBeenResumed: "您的请求已恢复",
  yourRequestIsCurrentlyPaused: "您的请求目前已暂停",
  serviceEdited: "已编辑",
  theFileSizeCannotExceed50M: "文件大小不能超过50M",
};