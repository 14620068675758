<template>
  <el-dialog
    :visible.sync="upgradeDialog"
    :modal-append-to-body="false"
    :before-close="handleUpgradeDialogClose"
    width="519px"
    custom-class="pay-tip-dialog"
  >
    <div class="pay-dialog-close-wrapper" @click="handleUpgradeDialogClose">
      <svg-icon icon-class="pay-dialog-close" class="pay-dialog-close" />
    </div>
    <div class="title">
      <svg-icon icon-class="lead-limit-tip-blue" class="icon-danger" />
      <span>Upgrade to Unlock This Feature</span>
    </div>
    <div>
      <div class="content1">This feature is available only for upgraded plan users.</div>
      <div class="content2">Upgrade your plan to enjoy enhanced functionality and unlock more possibilities tailored to your needs.</div>
    </div>
    <div class="btns-wrapper">
      <el-button class="btn-group-1" @click="handleUpgradeDialogClose">
        Back
      </el-button>
      <el-button class="btn-group-2" @click="addCard"> Upgrade Now</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapState('common', ['upgradeDialog'])
  },
  methods: {
    ...mapMutations('common', ['setUpgradeDialog']),
    addCard() {
      this.setUpgradeDialog(false);
      this.$router.push({ name: 'upgrade' });
    },
    handleUpgradeDialogClose() {
      this.setUpgradeDialog(false);
    }
  },
}
</script>
