export default {
  intentOverview: "概览",
  intentAnalytic: "分析",
  intentFilter: "筛选",
  searchCompany: "搜索公司、网站、LinkedIn...",
  savedCompanyWebsite: "已保存的公司网站",
  companiesFound: "间找到的公司",
  companyFound: "间找到的公司",
  filterList: {
    technologies: "技术",
    time: "时间",
    locations: "账户位置",
    verticals: "行业",
    pageview_greater_than: "页面浏览量大于",
    session_greater_than: "会话数大于",
    advertisements: "广告",
    company_groups: "列表",
    start_date: "开始日期",
    end_date: "结束日期",
    search_term: "搜索词",
  },
  employeeFilterList: {
    industry: "行业",
    technology: "技术",
    jobTitle: "职位名称",
    company: "公司",
    list: "列表",
    name: "姓名",
    size: "规模",
    location: "地点",
    jobFunction: "职务功能",
  },
  selectLists: "选择列表...",
  chooseARangeOfTime: "选择时间范围",
  greaterThan: "大于",
  selectAdvertisement: "选择广告",
  tabActivity: "活动",
  tabOverview: "概览",
  tabEmployees: "员工",
  tabContacts: "通用联系人",
  tabTechnologies: "技术",
  intentStatistic: "统计",
  intentCampaign: "活动",
  newVisitors: "新访客",
  recurringVisitors: "回访访客",
  mediocreLead: "一般潜在客户",
  sessionsTracked: "已追踪会话",
  sessionTracked: "已追踪会话",
  lastSeenOn: "最后一次出现在 ",
  firstSeenOn: "第一次出现在 ",
  viewTrackedWebSession: "查看已追踪的网络会话",
  webSession: "网络会话",
  client: "客户",
  timeSpent: "花费时间",
  pageviews: "页面浏览量",
  pageview: "页面浏览量",
  referrer: "来源",
  sessions: "会话数",
  session: "会话",
  visited: "已访问",
  AM: "上午",
  PM: "下午",
  companyDetails: "公司详细信息",
  overviewList: {
    location: "地点",
    industry: "行业",
    founded: "成立于",
    ranking: "排名",
    size: "规模",
    revenue: "预估收入",
    keywords: "关键字",
  },
  advancedSearch: "进阶搜索",
  jobFunction: "职务功能",
  employeeLocation: "位置",
  concatLocation: "联系位置",
  contactEmail: "电子邮件",
  categoryAndTechnology: "类别与技术",
  firstDetected: "首次检测",
  lastDetected: "最后检测",
  saved: "已保存",
  addToContactList: "添加到联系人列表",
  noCampaignIdentified: "未识别任何活动",
  copyEmail: "复制电子邮件",
  willBeSavedToTheFollowingSelectedLists: "将保存到以下选定列表：",
  addToLists: "添加到列表",
  noDataToDisplay: "无数据可显示",
  intentFounded: "成立于",
  intentIndustry: "行业",
  intentRevenue: "收入",
  intentSize: "规模",
  applyFilters: "应用筛选器",
  youCanEditTheseFilters: "您可以随时编辑这些筛选器以精炼结果",
  installB2BDirectLeadFinder: "在您的网站上安装B2B直接潜在客户查找器",
  addSite: "添加网站",
  areYouSureYouWantToRemoveThisWebsite: "您确定要移除此网站吗？",
  Cancel: "取消",
  installB2BLeadFinder: "安装B2B潜在客户查找器",
  siteUrl: "网站网址",
  copyToClipboard: "复制到剪贴板",
  pleaseSelectAContactFirst: "请先选择一个联系人",
  pasteCodeBelowIntoTheHead:
    "将以下代码粘贴到您希望追踪访问者的每个页面的头部中。",
  ofEveryPageYouWishToTrackVisitorOn: "的每个页面。",
  forWordpressWeRecommendInstalling:
    "对于WordPress，我们建议安装来自Stefano Lissa的插件。",
  pluginFromStefanoLissa: "插件。",
  afterInstallingAndActivating: "安装并启用插件后，请导航至",
  inYourWordPressDashboard: "在您的WordPress仪表板中。",
  youWillSeeANumberOfTabs:
    "您将在插件界面中看到多个标签。确保您在默认的“标头和页脚”标签中，然后复制并粘贴以下代码片段：",
  settingHeaderAndFooter: "设置 → 标头和页脚",
  dontForgetToClearYourCache: "不要忘记清除您的缓存。",
  IfYouAreUsingAnyCaChing: "如果您使用任何缓存插件，例如",
  orSimilar: "或类似的，请确保在安装代码片段后清除缓存！",
  GTMshouldAlreadyBeInstalled:
    "Google Tag Manager 应该已经安装在您的网站上，然后再按照这些说明操作。",
  IfYouDontHaveGTM:
    "如果您尚未安装Google Tag Manager，您可以在以下位置了解如何安装：",
  GTMsupportArticleAboutInstall: "Google Tag Manager 支持文章有关安装的内容。",
  signInToGTMAndClick:
    "登录Google Tag Manager，然后单击“添加新标签”按钮。接下来，",
  clickWhereItSays: "单击显示的地方，",
  chooseATagTypeToBeginSetup: "选择一个标签类型以开始设置",
  andSelect: "并选择 ",
  fromTheList: "从列表中。然后，将以下代码复制并粘贴到文本字段中：",
  nextClick: "接下来，单击 ",
  giveTheTagAName: ". 给标签命名，然后单击保存按钮。",
  dontForgetToPublishYourChange: "不要忘记发布您的更改",
  afterCreateTheCustom: "创建自定义HTML标签后，不要忘记 ",
  yourChanges: " 您的更改！",
  submitAndPublish: "提交并发布",
  copySuccessfully: "成功复制",
  addToCompanyList: "添加到公司列表",
  addToList: "添加到列表",
  enterOrCreateList: "输入或创建列表",
  installCode: "安装代码",
  wordpress: "WordPress",
  googleTagManager: "Google Tag Manager",
  noVisitorsYet: "尚无访问者",
  onceTheInsightsRollIn: "一旦获得见解，您将解锁有关访问者及更多的宝贵信息。",
  noTechAreFound: "未找到技术信息",
  updateSuccessfully: "成功更新",

  enterJobFunction: "输入职务功能",
  pinSuccessfully: "钉选成功",
  cancelPinSuccessfully: "取消钉选成功",
  adsName: "广告名称",
  utmTerm: "utmTerm",
  utmCampaign: "utmCampaign",
  utmSource: "utmSource",
  utmMedium: "utmMedium",
  enterNumber: "输入数字",
  enterName: "输入姓名",
  enterJobTitle: "输入职位名称",
  areYouSureYouWantToRemovePeopleOfThisGroup: "您确定要移除此组中的联系人吗？",
  areYouSureYouWantToRemoveCompanyOfThisGroup: "您确定要移除此组中的公司吗？",
  byCancelingYouEnsureThatNoPeopleWillBeRemoved:
    "通过取消，您可以确保不会移除任何联系人。",
  byCancelingYouEnsureThatNoCompanyWillBeRemoved:
    "通过取消，您可以确保不会移除任何公司。",
  howToGetStarted: "如何开始",
  Data: "条数据",
  deactivateWebsite: "停用网站",
  areYouCertainAboutDeactivatinYourWebsite:
    "您确定要停用您的网站吗？请注意，这将导致不接收任何潜在公司的活动。",
  byCancelingYouWillContinueToActivateAndUse:
    "通过取消，您将继续启用和使用此工具。",
  yourTrialHasEnded: "您的试用已结束。",
  reactivateNow: "立即重新启用",
  yourWebsiteHasBeenAutomaticallyInactivated:
    "您的网站已自动停用。您是否希望重新启用此工具以继续监控数据？",
  Inactivated: "已停用",
  onTrial: "试用中",
  Active: "启用设置",
  youAreOnTrail: "您正在进行7天的试用",
  yourTrailHasEnded: "您的试用已结束",
  createNewWebIntent: "创建新的网络意图",
  clickOnTheButton: "1. 点击（+）按钮",
  typeInUrlOrWebAddress: "2. 输入网址或网站地址",
  theTrialWillActivate: "勾选此框将启动7天的试用",
  pleaseEnterASiteUrl: "请输入网站网址",
  noMoreData: "没有更多数据",
  pleaseEnterANewName: "请输入一个新名称",
  pleaseSelectASearchName: "请选择一个搜索名称",
  clickToGetEmail: "点击以获取电子邮件",
  emailIsNotAvailable: "电子邮件不可用",
  enterOrCreateLists: "输入或创建列表",
  viewMore: "查看更多",
  removeFromContactList: "从联系人列表中移除",
  willBeRemovedFromTheFollowingLists: "将从以下列表中移除",
  removeFromLists: "从列表中移除",
  pleaseSelectOrCreateAList: "请选择或创建一个列表",
  chooseATriggerToMakeThisTagFire: "选择一个触发器以使此标签触发...",
  youWontBeChargedYet: "您尚未被收费",
  youDontHaveEnoughCredits: "您没有足够的信用点数",
  ThisEmailRequire: "此电子邮件需要",
  TheseEmailsRequires: "这些电子邮件需要",
  intentCredit: "信用点数",
  intentCredits: "信用点数",
};